
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class test extends Vue {
  private windowWidth = window.innerWidth;

  created() {
    console.log(this.$route.meta);
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }

}
